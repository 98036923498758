import { Compass } from "@enzymefinance/icons";
import { Badge, Breadcrumbs, Tooltip } from "@enzymefinance/ui";
import { useGlobals } from "components/providers/GlobalsProvider";
import { Routes } from "components/routing/Routes";
import { useVaultLastAssetUpdateQuery } from "queries/core";
import { useMemo } from "react";
import { Route, useLocation, useParams } from "react-router-dom";

import { Link } from "../../routing/Link";
import { useVault } from "../VaultProvider";

export function VaultBreadcrumbs() {
  const { id, vault } = useVault();
  const location = useLocation();

  return (
    <Breadcrumbs>
      <Breadcrumbs.Item as={Link} icon={Compass} to="/discover">
        Discover
      </Breadcrumbs.Item>

      <Breadcrumbs.Item as={Link} to={`/vault/${id}`} current={location.pathname.endsWith(id)}>
        <span className="flex items-center space-x-2">
          <span>{vault.name}</span>
          <RecentAssetUpdate />
        </span>
      </Breadcrumbs.Item>

      <Routes>
        {/* Public */}
        <Route
          path="portfolio"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Portfolio
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="financials"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Financials
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="fees"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Fees
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="policies"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Policies
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="depositors"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Depositors
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="activity"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Activity
            </Breadcrumbs.Item>
          }
        />

        {/* Manager */}
        <Route
          path="trade"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Trade
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="basket-trade"
          element={
            <Breadcrumbs.Item as={Link} to="" current={true}>
              Basket Trade
            </Breadcrumbs.Item>
          }
        />
        <Route
          path="protocols/*"
          element={
            <>
              <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("protocols")}>
                DeFi Protocols
              </Breadcrumbs.Item>
              <Routes>
                <Route
                  path="flexible-loan"
                  element={
                    <Breadcrumbs.Item as={Link} to="">
                      Flexible Loan
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="idle"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Idle Finance
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="yearn"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Yearn Finance
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="aave-v2/*"
                  element={
                    <>
                      <Breadcrumbs.Item as={Link} to="">
                        Aave V2
                      </Breadcrumbs.Item>
                      <Routes>
                        <Route
                          path="supply"
                          element={
                            <Breadcrumbs.Item as={Link} to="" current={true}>
                              Supply
                            </Breadcrumbs.Item>
                          }
                        />
                        <Route
                          path="borrow"
                          element={
                            <Breadcrumbs.Item as={Link} to="" current={true}>
                              Borrow
                            </Breadcrumbs.Item>
                          }
                        />
                      </Routes>
                    </>
                  }
                />
                <Route
                  path="aave-v3/*"
                  element={
                    <>
                      <Breadcrumbs.Item as={Link} to="">
                        Aave V3
                      </Breadcrumbs.Item>
                      <Routes>
                        <Route
                          path="supply"
                          element={
                            <Breadcrumbs.Item as={Link} to="" current={true}>
                              Supply
                            </Breadcrumbs.Item>
                          }
                        />
                        <Route
                          path="borrow"
                          element={
                            <Breadcrumbs.Item as={Link} to="" current={true}>
                              Borrow
                            </Breadcrumbs.Item>
                          }
                        />
                      </Routes>
                    </>
                  }
                />
                <Route
                  path="compound-v3"
                  element={
                    <Breadcrumbs.Item as={Link} to="">
                      Compound V3
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="compound-v2/*"
                  element={
                    <>
                      <Breadcrumbs.Item as={Link} to="">
                        Compound V2
                      </Breadcrumbs.Item>
                      <Routes>
                        <Route
                          path="supply"
                          element={
                            <Breadcrumbs.Item as={Link} to="" current={true}>
                              Supply
                            </Breadcrumbs.Item>
                          }
                        />
                        <Route
                          path="borrow"
                          element={
                            <Breadcrumbs.Item as={Link} to="" current={true}>
                              Borrow
                            </Breadcrumbs.Item>
                          }
                        />
                      </Routes>
                    </>
                  }
                />
                <Route
                  path="convex"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("convex")}>
                      Convex Finance
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="uniswap-v2"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("uniswap-v2")}>
                      Uniswap V2
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="balancer"
                  element={
                    <Breadcrumbs.Item as={Link} to="">
                      Balancer
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="curve"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("curve")}>
                      Curve
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="maple"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("maple")}>
                      Maple Finance
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="pendle-v2"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("pendle-v2")}>
                      Pendle V2
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="gmx-v2"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("gmx-v2")}>
                      GMX V2
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="morpho"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("morpho")}>
                      Morpho
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="spark"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("spark")}>
                      Spark
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="vaultcraft"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("vaultcraft")}>
                      VaultCraft
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="swell"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("swell")}>
                      Swell
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="delegate-votes"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("delegate-votes")}>
                      Delegate Votes
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="the-graph"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("the-graph")}>
                      The Graph
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="uniswap-v3/*"
                  element={
                    <>
                      <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("uniswap-v3")}>
                        Uniswap V3
                      </Breadcrumbs.Item>
                      <Routes>
                        <Route
                          path="new"
                          element={
                            <Breadcrumbs.Item as={Link} to="" current={true}>
                              New Position
                            </Breadcrumbs.Item>
                          }
                        />
                        <Route path=":id" element={<VaultBreadcrumbs.IdItem />} />
                      </Routes>
                    </>
                  }
                />
                <Route
                  path="liquity"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={location.pathname.endsWith("liquity")}>
                      Liquity
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="kiln"
                  element={
                    <Breadcrumbs.Item as={Link} to="">
                      Kiln
                    </Breadcrumbs.Item>
                  }
                />
              </Routes>
            </>
          }
        />

        <Route
          path="settings/*"
          element={
            <>
              <Breadcrumbs.Item as={Link} to={`/vault/${id}/settings`} current={location.pathname.endsWith("settings")}>
                Settings
              </Breadcrumbs.Item>
              <Routes>
                <Route
                  path="assets"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Asset Management
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="fees"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Fees
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="policies"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Policies
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="reconfigure"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Reconfigure
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="change-owner"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Change Owner
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="change-name"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Change Name
                    </Breadcrumbs.Item>
                  }
                />
                <Route
                  path="upgrade"
                  element={
                    <Breadcrumbs.Item as={Link} to="" current={true}>
                      Upgrade
                    </Breadcrumbs.Item>
                  }
                />
              </Routes>
            </>
          }
        />
      </Routes>
    </Breadcrumbs>
  );
}

function RecentAssetUpdate() {
  const { id } = useVault();

  const lastAssetUpdateQuery = useVaultLastAssetUpdateQuery({ variables: { vault: id } });

  const lastAssetUpdate = useMemo(
    () => lastAssetUpdateQuery.data?.vault?.lastAssetUpdate ?? 0,
    [lastAssetUpdateQuery.data?.vault?.lastAssetUpdate],
  );

  const now = Math.floor(Date.now() / 1000);
  const secondsSinceLastAssetUpdate = now - lastAssetUpdate;

  return secondsSinceLastAssetUpdate < 300 ? (
    <Tooltip.Provider>
      <Tooltip.Item>
        <Badge>Indexing ...</Badge>
      </Tooltip.Item>
      <Tooltip.Panel>
        <span className="block max-w-xs text-sm">
          This vault recently had a deposit, withdrawal or a trade. It can take a few minutes until the new data is
          displayed. We are working on a solution to provide faster updates.
        </span>
      </Tooltip.Panel>
    </Tooltip.Provider>
  ) : null;
}

function vaultBreadcrumbsAssetItem() {
  const { asset: assetAddress } = useParams<"asset">();
  const { environment } = useGlobals();
  const asset = useMemo(() => {
    if (assetAddress === undefined) {
      return undefined;
    }

    return environment.getAsset(assetAddress);
  }, [environment, assetAddress]);

  return (
    <Breadcrumbs.Item as={Link} to="">
      {asset?.symbol}
    </Breadcrumbs.Item>
  );
}

VaultBreadcrumbs.AssetItem = vaultBreadcrumbsAssetItem;

function vaultBreadcrumbsIdItem() {
  const { id } = useParams<"id">();

  return (
    <Breadcrumbs.Item as={Link} to="">
      {id}
    </Breadcrumbs.Item>
  );
}

VaultBreadcrumbs.IdItem = vaultBreadcrumbsIdItem;
