import { MoonIcon, SunIcon } from "@enzymefinance/icons/src/outline";
import { Button, Icon } from "@enzymefinance/ui";
import { useDarkLightMode } from "components/providers/DarkLightModeProvider";

export function DarkModeSwitch() {
  const { isDarkModeOn, switchDarkMode } = useDarkLightMode();

  return (
    <Button size="sm" className="py-5" appearance="tertiary" onClick={switchDarkMode}>
      <Icon icon={isDarkModeOn ? MoonIcon : SunIcon} />
    </Button>
  );
}
